@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@700&family=Rubik:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', 'Arial', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: #f5f5f5;*/
  background: rgb(255,255,255);
  background: linear-gradient(-15deg, rgba(112, 76, 182, 0) 0%, rgba(112, 76, 182, .1) 100%) no-repeat fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  margin: 0;
  padding: 0;
}

body {
  height: 100%
}

html {
  height: 100%;
}

a:link,
a:visited {
  color: rgb(112, 76, 182);
  text-decoration: none;
}

a:hover,
a:active {
  color: rgb(112, 76, 182);
  text-decoration: underline;
}

/* Fix for React Leaflet map buttons */ 
#map-container a:hover,
#map-container a:active {
  text-decoration: none !important;
}

.form-required::after {
  display: inline-block;
  margin-inline-start: 4px;
  color: #ff4d4f;
  font-size: 1em;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}

.display-none {
  display: none;
}

.loading {
  margin-top: 5em;
  margin-bottom: 5em;
  display: flex;
  justify-content: center;
}

.text-green {
  color: #090;
}

.text-red {
  color: #900;
}