#root {
    height: 100%;
    width: 100%;
    margin: 0 auto;
}

.App
{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1024px;
}

header.top {
    background: #fff;
    margin-bottom: 1em;
}

.top-container {
    display: flex;
    padding: 2em 1em;
    align-items: center;
    justify-content: space-between;
}

header.top a:link,
header.top a:visited {
    color: #000;
    text-decoration: none;
}

header.top a:hover,
header.top a:active {
    text-decoration: underline;
}

.logo {
    display: flex;
    align-items: center;
}

.logo-icon {
    font-size: 1.5em;
    height: 1em;
    margin-right: 0.3em;
    color: rgb(112, 76, 182);
}

.logo h1 {
    font-size: 1.5em;
    font-weight: 400;
}

ul.menu {
    justify-content: flex-end;
    list-style-type: none;
    display: flex;
    font-size: .9em;
    font-weight: 500;
}

ul.menu li {
    margin: 0 1em;
    display: flex;
    align-items: center;
}

ul.menu li:last-of-type {
    margin: 0 0 0 1em;
}

ul.menu .menu-icon {
    display: block;
    font-size: 2em;
    height: 1em;
}

ul.menu a:link,
ul.menu a:visited {
    color: #666;
    text-decoration: none;
}

.content {
    background: #fff;
    padding: 1em;
    border-radius: 3px;
}

@media only screen and (max-width: 600px) {
    .menu-hide {
        display: none !important;
    }

    .menu-show {
        display: inline-block;
    }
}

@media only screen and (min-width: 600px) {
    .menu-show {
        display: none !important;
    }
}

.hero {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}

.hero-line {
    position: relative;
    display: inline-block;
}

.hero-line-line {
    position: absolute;
    left: 0;
    width: 100%;
}

.hero-line-line svg {
    width: 100%;
}

.hero-line-line-color {
    fill: rgba(112, 76, 182, 0.75);
}

.hero-image {
    width: 100%;
}

.hero-image img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .hero {
        flex-direction: column;
    }

    .hero h3 {
        font-weight: 300;
        text-align: center;
        font-size: 1.15em;
        line-height: 1.5em;
        margin-bottom: 2em;
    }

    .hero-line-line {
        bottom: -.9em;
    }
}

@media only screen and (min-width: 601px) {
    .hero {
        flex-direction: row;
    }

    .hero h3 {
        font-weight: 300;
        text-align: center;
        font-size: 1.75em;
        line-height: 1.5em;
    }

    .hero-line-line {
        bottom: -.7em;
    }
}

.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #999;
    font-size: .8em;
}

.footer div {
    text-align: center;
    margin-bottom: 0.5em;
}

.footer ul {
    list-style-type: none;
    display: inline-flex;
    justify-content: center;
    margin-bottom: 1em;
}

.footer ul li {
    margin: 0 0.5em;
}

.hide {
    display: none !important;
}

.content h1 {
    font-family: "Nunito Sans", "Arial", "Helvetica", sans-serif;
    font-size: 1.7em;
}

.content h2 {
    font-family: "Nunito Sans", "Arial", "Helvetica", sans-serif;
    font-size: 1.2em;
    margin-bottom: .5em;
}

.content p,
.content .text {
    line-height: 1.5em;
    color: #666;
    margin-bottom: 2em;
}

@media only screen and (min-width: 601px) {

    .event-intro {
        display: flex;
        flex-direction: row-reverse;
    }

    .event-intro .header {
        flex-grow: 5;
    }

    .event-intro .organizer {
        margin-left: 2em;
    }
}

@media only screen and (max-width: 600px) {
    .organizer ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    .organizer li {
        margin: 0 0.35em;
    }
}

.organizer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.organizer-logo img {
    width: 80%;
    margin-bottom: 1em;
}

.event-intro .organizer {
    /*border: 1px solid rgb(112, 76, 182);*/
    border-radius: 3px;
    padding: .75em;
    line-height: 1.5em;
    margin-bottom: 1em;
    /*background: rgba(112, 76, 182, 0.05);*/
}

.organizer-title {
    text-align: center;
    font-size: .9em;
    margin-bottom: 0.5em;
    /*font-family: "Nunito Sans", "arial", "Helvetica", sans-serif;*/
    color: #666;
}

.organizer-title-name {
    display: inline-block;
    font-weight:  500;
    color: #000;
    font-size: 1.4em;
}

.organizer ul {
    list-style-type: none;
}

.organizer li {
    margin-bottom: .25em;
}

.organizer li a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.organizer .organizer-icon {
    font-size: 1.5em;
    height: 1em;
    margin-right: .25em;
}

ul.route-buttons {
    list-style-type: none;
    display: flex;
    flex-direction: column;
}

ul.route-buttons li {
    margin-bottom: .5em;
}
ul.route-buttons span {
    display: block;
    margin-right: .5em;
    height: 1em;
}

.button-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-icon {
    margin-right: .5em;
    height: 1em;
}

@media only screen and (max-width: 600px) {
    .xs-hidden {
        display: none;
    }
}

.layout-container {
    flex-grow: 1;
    margin-bottom: 1em;
    padding: 0 1em;
}

.route-title h2 {
    color: #666;
}

@media only screen and (max-width: 600px) {
    .route-title {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .route-title h1 {
        text-align: center;
        margin-bottom: 0.25em;
    }
}

@media only screen and (min-width: 601px) {
    .route-title {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
    }
    
    .route-title h1,
    .route-title h2 {
        margin-right: .5em;
    }
}

.container-narrow {
    width: 100%;
    margin: 0 auto;
    max-width: 512px;
}